<template>
  <v-container
    fluid
    class="pa-0 d-flex flex-column algin-center"
    :class="$vuetify.theme.dark ? 'background_light' : 'background_normal'"
    style="height: 100vh"
  >
    <sign-in
      v-if="type == 1"
      @changeType="changeType"
      class="mx-auto my-auto"
    />
    <reset-password v-if="type == 2" @changeType="changeType" />
  </v-container>
</template>

<style></style>
<script>
if (!window.Kakao.isInitialized()) {
  window.Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY_WEB);
  window.Kakao.isInitialized();
}

import SignIn from "@/components/Authorization/signIn";
import setMeta from "@/utils/setMeta";
import ResetPassword from "../../components/Authorization/resetPassword.vue";

export default {
  components: {
    SignIn,
    ResetPassword,
  },
  data() {
    return {
      type: 1,
    };
  },
  methods: {
    changeType(index) {
      this.type = index;
    },
  },

  created() {
    setMeta({
      title: "로그인",
      description: "메이드올의 온라인 메이커 플랫폼입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
    // if (this.$store.getters["auth/user/GET_USER"]) {
    //   this.type = 2;
    // }
  },
};
</script>
