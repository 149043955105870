var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto my-auto d-flex flex-column justify-center pa-15",class:_vm.$vuetify.breakpoint.mdAndUp
      ? 'px-15'
      : _vm.$vuetify.breakpoint.sm
      ? 'px-10'
      : 'px-5',attrs:{"flat":"","color":_vm.$vuetify.breakpoint.mdAndUp ? 'background_light' : 'transparent',"width":"520","rounded":"lg"}},[_c('span',{staticClass:"h3 bold onSurface_dark--text text-center mt-0 mb-5"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.title"))+" ")]),_c('v-form',{ref:"form",staticClass:"d-flex flex-column",staticStyle:{"gap":"12px"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-select',{staticClass:"select",attrs:{"items":_vm.countries,"item-text":"name","item-value":"name","rules":[_vm.rules.required],"attach":".select","hide-details":"auto","outlined":"","solo":"","loader-height":"100","flat":"","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"height":"53","menu-props":{
        maxHeight: 250,
        offsetY: true,
        contentClass: 'elevation-1',
      }},on:{"change":_vm.onSelectChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"6px"}},[_c('span',{staticClass:"primary--text subtitle-2 regular"},[_vm._v(" "+_vm._s(_vm.$t("login.region"))+" ")]),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"6px"}},[_c('v-img',{attrs:{"max-width":"22","src":_vm.flagFileName(item)}}),_c('span',{staticClass:"text-capitalize onSurface_dark--text"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "ko" ? item.name_kor : item.name)+" ("+_vm._s(item.code)+") ")])],1)])]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center",staticStyle:{"gap":"6px","max-width":"100%"}},[_c('v-img',{attrs:{"max-width":"22","max-height":"16","src":_vm.flagFileName(item)}}),_c('span',{staticClass:"text-capitalize onSurface_dark--text"},[_vm._v(" "+_vm._s(_vm.$i18n.locale == "ko" ? ((item.name_kor) + " (" + (item.code) + ") (" + (item.name) + ")") : ((item.name) + " (" + (item.code) + ")"))+" ")])],1)]}},{key:"message",fn:function(ref){
      var message = ref.message;
return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}]),model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
              ? 'onSurface_normal--text'
              : 'background_dark--text'},[_vm._v(" "+_vm._s(_vm.$t("login.region"))+" ")])]),_c('template',{slot:"append"},[_c('v-icon',{attrs:{"size":"16","color":"onSurface_light"}},[_vm._v("mdi-chevron-down")])],1)],2),_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.emailOrPhone],"required":"","hide-details":"auto","solo":"","flat":"","dense":"","outlined":"","background-color":_vm.$vuetify.theme.dark ? 'background_normal' : 'background_light',"height":"53"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if (_vm.valid) {
          _vm.resetPassword();
        }}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
        var message = ref.message;
return [_c('span',[_vm._v(_vm._s(_vm.$t(message)))])]}}]),model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"title-2 regular",class:_vm.$vuetify.theme.dark
              ? 'onSurface_normal--text'
              : 'background_dark--text'},[_vm._v(_vm._s(_vm.$t("login.phone")))])])],2),_c('v-btn',{staticClass:"mt-5",class:_vm.$vuetify.breakpoint.mdAndUp ? 'title-1 bold' : 'subtitle-1 regular',attrs:{"color":"primary","large":"","disabled":!_vm.valid || _vm.form.email == '',"depressed":"","block":"","height":"53","loading":_vm.resetBtnLoading},on:{"click":_vm.resetPassword}},[_vm._v(_vm._s(_vm.$t("resetPassword.sendBtn"))+" ")])],1),_c('div',{staticClass:"mt-5 title-2 regular",class:_vm.$vuetify.breakpoint.xs ? 'subtitle-1' : ''},[_c('span',{staticClass:"onSurface_normal--text"},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.isAccountExist"))+" ")]),_c('span',{staticClass:"hover-pointer primary--text ml-1 font-weight-bold",on:{"click":function($event){return _vm.$emit('changeType', 1)}}},[_vm._v(" "+_vm._s(_vm.$t("resetPassword.signin"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }