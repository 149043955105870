<template>
  <v-card
    flat
    :color="$vuetify.breakpoint.mdAndUp ? 'background_light' : 'transparent'"
    width="520"
    rounded="lg"
    class="mx-auto my-auto d-flex flex-column justify-center pa-15"
    :class="
      $vuetify.breakpoint.mdAndUp
        ? 'px-15'
        : $vuetify.breakpoint.sm
        ? 'px-10'
        : 'px-5'
    "
  >
    <span class="h3 bold onSurface_dark--text text-center mt-0 mb-5">
      {{ $t("resetPassword.title") }}
    </span>
    <v-form
      v-model="valid"
      ref="form"
      class="d-flex flex-column"
      style="gap: 12px"
    >
      <v-select
        v-model="form.country"
        :items="countries"
        item-text="name"
        item-value="name"
        :rules="[rules.required]"
        class="select"
        attach=".select"
        hide-details="auto"
        outlined
        solo
        loader-height="100"
        flat
        :background-color="
          $vuetify.theme.dark ? 'background_normal' : 'background_light'
        "
        height="53"
        :menu-props="{
          maxHeight: 250,
          offsetY: true,
          contentClass: 'elevation-1',
        }"
        @change="onSelectChange"
      >
        <template slot="label">
          <span
            class="title-2 regular"
            :class="
              $vuetify.theme.dark
                ? 'onSurface_normal--text'
                : 'background_dark--text'
            "
          >
            {{ $t("login.region") }}
          </span>
        </template>
        <template slot="append">
          <v-icon size="16" color="onSurface_light">mdi-chevron-down</v-icon>
        </template>
        <template v-slot:selection="{ item }">
          <div class="d-flex flex-column" style="gap: 6px">
            <span class="primary--text subtitle-2 regular">
              {{ $t("login.region") }}
            </span>
            <div class="d-flex" style="gap: 6px">
              <v-img max-width="22" :src="flagFileName(item)" />
              <span class="text-capitalize onSurface_dark--text">
                {{ $i18n.locale == "ko" ? item.name_kor : item.name }} ({{
                  item.code
                }})
              </span>
            </div>
          </div>
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex align-center" style="gap: 6px; max-width: 100%">
            <v-img max-width="22" max-height="16" :src="flagFileName(item)" />
            <span class="text-capitalize onSurface_dark--text">
              {{
                $i18n.locale == "ko"
                  ? `${item.name_kor} (${item.code}) (${item.name})`
                  : `${item.name} (${item.code})`
              }}
            </span>
          </div>
        </template>
        <template v-slot:message="{ message }">
          <span>{{ $t(message) }}</span>
        </template>
      </v-select>
      <v-text-field
        v-model="form.phone"
        :rules="[rules.required, rules.emailOrPhone]"
        required
        hide-details="auto"
        solo
        flat
        dense
        outlined
        :background-color="
          $vuetify.theme.dark ? 'background_normal' : 'background_light'
        "
        height="53"
        @keydown.enter="
          if (valid) {
            resetPassword();
          }
        "
      >
        <template slot="label">
          <span
            class="title-2 regular"
            :class="
              $vuetify.theme.dark
                ? 'onSurface_normal--text'
                : 'background_dark--text'
            "
            >{{ $t("login.phone") }}</span
          >
        </template>
        <template v-slot:message="{ message }">
          <span>{{ $t(message) }}</span>
        </template>
      </v-text-field>
      <v-btn
        color="primary"
        large
        :disabled="!valid || form.email == ''"
        depressed
        class="mt-5"
        :class="
          $vuetify.breakpoint.mdAndUp ? 'title-1 bold' : 'subtitle-1 regular'
        "
        block
        height="53"
        :loading="resetBtnLoading"
        @click="resetPassword"
        >{{ $t("resetPassword.sendBtn") }}
      </v-btn>
    </v-form>
    <div
      class="mt-5 title-2 regular"
      :class="$vuetify.breakpoint.xs ? 'subtitle-1' : ''"
    >
      <span class="onSurface_normal--text">
        {{ $t("resetPassword.isAccountExist") }}
      </span>
      <span
        class="hover-pointer primary--text ml-1 font-weight-bold"
        @click="$emit('changeType', 1)"
      >
        {{ $t("resetPassword.signin") }}
      </span>
    </div>
  </v-card>
</template>
<style scoped>
.recaptcha-terms-text {
  font-size: 12px;
  font-weight: 200;
  color: #637282;
}

::v-deep .v-input__slot fieldset {
  border-color: var(--v-background_dark-base);
}

.v-btn.v-btn--disabled {
  background-color: var(--v-background_dark-base) !important;
  color: #fefefe !important;
}
</style>

<script>
import API from "@/API/auth";
import countries from "@/assets/flags/flags.js";
import rules from "@/utils/rules.js";

export default {
  created() {
    this.valid = false;
  },
  mounted() {
    API.getCountry().then((res) => {
      let userCountry = countries.find(
        (el) => el.countryCode == res.data.countryCode,
      );
      this.form.country = userCountry.name;
    });
  },
  data() {
    return {
      rules: rules,
      countries: countries,
      emailSignUp: false,
      valid: false,
      resetBtnLoading: false,
      form: {
        country: undefined,
        phone: "",
      },
    };
  },
  methods: {
    /**
     * 국가 선택을 바탕으로 자동으로 페이지 언어를 변환합니다.
     */
    onSelectChange(select) {
      this.$i18n.locale = select.includes("korea") ? "ko" : "en";
    },
    flagFileName(item) {
      let filename = item.flag ? item.flag : item.name;
      filename = filename.replaceAll(" ", "-");
      return require("@/assets/flags/" + filename + ".svg");
    },

    async resetPassword() {
      if (!this.$refs.form.validate())
        return this.$toasted.global.error("입력 폼을 올바르게 작성해주세요.");
      this.resetBtnLoading = true;

      if (this.form.phone.match(/.+@.+/)) {
        API.resetPasswordByEmail(this.form.phone).finally(() => {
          this.resetBtnLoading = false;
        });

        return;
      }

      if (this.form.phone.match(/(^\d{2,3}-\d{3,4}-\d{4}$)|(^\d{9,11}$)/)) {
        this.form.phone.replaceAll(/-/g, "");

        if (this.form.phone.match(/^\d{9,11}$/)) {
          const country = this.countries.find(
            (el) => el.name == this.form.country,
          );
          if (country == undefined) {
            console.error("Can't find country in datas.");
            return;
          }

          const code = country.code;
          let temp = this.form.phone;

          if (code != "+82" || this.form.phone[0] == "0") {
            temp = temp.slice(1);
          }
          this.form.phone = code + temp;
        }
        API.resetPasswordByPhone(this.form.phone).finally(() => {
          this.resetBtnLoading = false;
        });
        return;
      }
    },
  },
};
</script>
